<template>
   <layout-page :classes="pageClasses">
      <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
    <section v-else>
      <loading v-if="dataLoading"></loading>
       <template v-else>
     <HeadPage :rawTitle="page[0].title.rendered" :content="page[0].content.rendered" :image="page[0].fimg_url.full" />
     <Management :title="renderTitle(page[0].acf.title_1)" :data="page[0].acf.group_team" />
     <Quotes :image="page[0].acf.quotes_bg" :title="page[0].acf.title_2" :data="page[0].acf.group_quotes" />
     </template>
     </section>
    </layout-page>
    
</template>

<script>
import LayoutPage from "@/layouts/LayoutPage.vue";
import HeadPage from "@/components/template-parts/HeadPage.vue";
import Management from "@/components/people/Management.vue";
import Quotes from "@/components/people/Quotes.vue";
import Loading from '@/components/Loading.vue';
export default {
components: {
    LayoutPage,HeadPage,Management, Quotes, Loading
  },
   data() {
    return {
      fetchSlug: String,
      itLoads: false,
      dataLoading: true,
      dataErrored: false,
      page: [
        {
          title:{
            rendered: 'Page'
          }
        }
      ],
      refe: Array,
      pageTitle: Array,
      pageClasses: ["internal-page", this.$route.name],
    };
  },
  head() {
    return {
      title: this.page[0]._yoast_wpseo_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page[0]._yoast_wpseo_metadesc,
        },
      ],
    };
  },
   methods: {
      fetchingData() {
        this.fetchSlug = this.$route.path;
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/pages/?slug=' +this.fetchSlug )
          .then((response) => {
            this.page = response.data;
            this.itLoads = true
          })
          .catch((error) => {
            console.log(error);
             this.dataErrored = true
          }).finally(() => this.dataLoading = false);
      },
      renderTitle(stg){
        let inputstg = stg.split(" ");
        inputstg[0] = '<span class="tx__c-primary">'+inputstg[0]+'</span>';
        inputstg = inputstg.join(' ');
        return inputstg;
      }
  },
  created() {
    this.fetchingData();
  },
  mounted(){
    
  },
}
</script>

<style>

</style>