<template>
  <div
    class="container-fluid py-5 text-white slider-quotes position-relative bg__img"
    :style="{ backgroundImage: 'url(' + image + ')' }"
  >
  <span class="rect rect-top d-none d-lg-block"></span>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 pt-5">
        <div class="row pb-5">
          <div class="col-12 offset-lg-6 col-lg-6">
            <h2
              class="text-uppercase tx__w-bold mx-auto  text-center text-lg-right title"
              v-if="title"
              v-html="title"
            ></h2>
          </div>
        </div>

      </div>
    </div>
     <div class="row justify-content-center pb-5">
         <div class="col-12 col-lg-6">
             <div class="row align-items-center">
          <div class="col-3 border__right border__right--white d-flex justify-content-end">
              
              <div class="nav-vertical-splide d-flex flex-column justify-content-center pr-lg-3">
                 <button @click="goPrev" class="bt__icon"><i class="fas fa-chevron-up"></i></button>
                 <span class="index-counter tx__c-primary" v-if="data.length">0{{sliderIndex}}</span>
                 <hr>
                 <span class="total-counter">0{{data.length}}</span>
                <button @click="goNext" class="bt__icon"><i class="fas fa-chevron-down"></i></button>
              </div>
              
          </div>
          <div class="col-9 col-md-7">
            <splide ref="quotes" :options="options" v-if="data.length">
              <splide-slide v-for="(i, index) in data" :key="index">
                <div class="w-100 h-100 d-flex align-items-center">
                    <p class="tx__size-r">{{ i.quote }}</p>
                  
                </div>
              </splide-slide>
            </splide>
          </div>
        </div>
         </div>
    </div>
    <span class="rect rect-bottom d-none d-lg-block"></span>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide-core.min.css";
export default {
  props: {
    data: Array,
    title: String,
    image: String,
  },
  data() {
    return {
      options: {
        type: "loop",
        arrows: false,
        pagination: false,
        direction: "ttb",
        height: "12rem",
        drag: false,
         breakpoints: {
		      576: {
			       height: "25rem",
          },
          768: {
           height: "15rem",
            
          },
        }
      },
      sliderIndex: 1
    };
  },
  components: {
    Splide,
    SplideSlide,
  },
  methods: {
    goNext(){
      this.$refs.quotes.go( '+' );
      this.sliderIndex = this.$refs.quotes.index+1;
    },
    goPrev(){
      this.$refs.quotes.go( '-' );
      this.sliderIndex = this.$refs.quotes.index+1;
    },
    getSIndex(){
      if (!this.$refs.quotes) return 0;
      return this.$refs.quotes.index;
    }
  },
  mounted(){
    
  },
  computed: {
    getSliderIndex: function () {
      return this.$refs.quotes.index;
    }
  }
};
</script>

<style>
</style>