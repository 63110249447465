<template>
  <div class="w-100 ">
    <div class="container-fluid py-5 mt-5">
    <div class="row pt-5">
      <div class="col-12">
        <h2 class="text-uppercase tx__w-bold mx-auto text-center" v-if="title" v-html="title">
          </h2>
      </div>
    </div>
  </div>
  <div class="container-fluid sliders-team pb-lg-4">
      <div class="row pb-lg-5">
        <div class="col-12" >
          <splide ref="team" :options="options" v-if="data.length">
          <splide-slide v-for="(i, index) in data" :key="index">
            <div class="w-100 item-slider">
              
              
                <template v-if="index % 2 !== 0">
                <div class="w-100 name-referee text-white pb-4 odd d-none d-lg-block">
                <h3 class="text-center tx__size-r">{{i.name}}</h3>
                <span class="line "></span>
                <p class="text-center pt-2">{{i.position}}</p>
                <a :href="i.linkedin" v-if="i.linkedin" target="_blank" class="icon-sm d-block m-auto text-center"><i class="fab fa-linkedin"></i></a>
              </div>
              </template>

              <figure class="figure figure__portrait">
                <img
                  :src="i.photo"
                 :alt="i.name"
                  class="img"
                />
              </figure>
              <template v-if="index % 2 !== 0">
                <div class="w-100 name-referee text-white py-4 even d-lg-none">
                <h3 class="text-center tx__size-r">{{i.name}}</h3>
                <span class="line "></span>
                <p class="text-center pt-2">{{i.position}}</p>
                <a :href="i.linkedin" v-if="i.linkedin" target="_blank" class="icon-sm d-block m-auto text-center"><i class="fab fa-linkedin"></i></a>
              </div>
              </template>
              <template v-if="index % 2 === 0">
                <div class="w-100 name-referee text-white py-4 even">
                <h3 class="text-center tx__size-r">{{i.name}}</h3>
                <span class="line "></span>
                <p class="text-center pt-2">{{i.position}}</p>
                <a :href="i.linkedin" v-if="i.linkedin" target="_blank" class="icon-sm d-block m-auto text-center"><i class="fab fa-linkedin"></i></a>
              </div>
              </template>
              
            </div>
          </splide-slide>
        </splide>

        </div>
      </div>
    </div>
    <div class="container-fluid bg__secondary pb-5">
      <div class="row pb-5">
        <div class="col-12">
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import '@splidejs/splide/dist/css/splide-core.min.css';
export default {
    props: {
    data: Array,
    title: String
  },
    data(){
    return{
      options:{
        // type: "loop",
        rewind : true,
        perPage: 3,
        perMove:1,
        arrows: true,
        pagination: false,
        gap: '1.5rem',
        easing: "ease",
        speed: 800,
        breakpoints: {
		      576: {
			      perPage: 1,
          },
          768: {
            perPage: 2,
            
          },
        }
      }
    }
  },
  components: {
    Splide,
    SplideSlide,
  },
  methods: {
    goNext(){
      this.$refs.team.go( '+' );
    },
    goPrev(){
      this.$refs.team.go( '-' );
    },
  },
}
</script>

<style>

</style>